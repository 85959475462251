<template>
  <the-new-header>
    <template v-slot:main-block>&nbsp;</template>
  </the-new-header>
  <div class="page-content page-404">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">
      <transition name="fade">
        <section class="not-found">
          <div class="new-container">
            <div class="not-found-block">
              <img src="@/assets/images/aso-404.svg" class="image-404" width="314" height="280"
                   alt="Page not found image">
              <h1 class="page-title">Ooops… page not found!</h1>
            </div>
          </div>
        </section>
      </transition>
    </main>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TheNewHeader from "@/components/TheNewHeader/index.vue";

export default {
  name: "NotFound",
  components: {TheNewHeader},
  computed: {
    ...mapGetters([
      'isMobile',
      'sidebarIsCollapsed',
    ]),
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>